<template>
  <div class="content-computed" id="contentComputed">
    <div class="isPc1">
      <div class="three-content-computed">
        <div class="three-content-computed-item">
          <div class="three-content-computed-item-one">
            <div class="three-content-computed-item-one-left">
              <div class="three-content-computed-item-one-left-title">
                {{ $t("index.v1.text6") }}
              </div>
              <div class="three-content-computed-item-one-left-item">
                <div class="three-content-computed-item-one-left-item-header">
                  <div>
                    <img src="@/assets/newQJ/index/image/01@2x.png" alt="" />
                    <span>{{ $t("index.v1.text7") }}</span>
                  </div>
                </div>
                <div class="three-content-computed-item-one-left-item-center">
                  <p>{{ $t("index.v1.text8") }}</p>
                </div>
              </div>
            </div>
            <div class="ggg"></div>
            <div class="three-content-computed-item-one-right">
              <img src="@/assets/newQJ/index/image/sb/1111@2x.png" alt="" />
            </div>
          </div>
          <div class="three-content-computed-item-two">
            <div
              class="three-content-computed-item-two-item"
              style="background-color: #eae7db"
            >
              <div class="three-content-computed-item-one-left-item-header">
                <div>
                  <img src="@/assets/newQJ/index/image/02@2x.png" alt="" />
                  <span>{{ $t("index.v1.text9") }}</span>
                </div>
              </div>
              <div class="three-content-computed-item-one-left-item-center">
                <p>{{ $t("index.v1.text10") }}</p>
                <p>
                  {{ $t("index.v1.text11") }}
                </p>
                <p>{{ $t("index.v1.text12") }}</p>
              </div>
            </div>
            <div class="ggg"></div>
            <div
              class="three-content-computed-item-two-item"
              style="background-color: #a39796"
            >
              <div class="three-content-computed-item-one-left-item-header">
                <div>
                  <img src="@/assets/newQJ/index/image/03@2x.png" alt="" />
                  <span class="white">{{ $t("index.v1.text13") }}</span>
                </div>
              </div>
              <div
                class="three-content-computed-item-one-left-item-center white"
              >
                <p>{{ $t("index.v1.text14") }}</p>
                <p>
                  {{ $t("index.v1.text15") }}
                </p>
              </div>
            </div>
          </div>
          <div class="three-content-computed-item-two">
            <div class="three-content-computed-item-two-item w25">
              <img
                class="defaultMinHeight"
                src="@/assets/newQJ/index/image/sb/2222@2x.png"
                alt=""
              />
            </div>
            <div class="ggg"></div>
            <div
              class="three-content-computed-item-two-item w44"
              style="background-color: #a4a4a1"
            >
              <div class="three-content-computed-item-one-left-item-header">
                <div>
                  <img src="@/assets/newQJ/index/image/04@2x.png" alt="" />
                  <span class="white">{{ $t("index.v1.text16") }}</span>
                </div>
              </div>
              <div
                class="three-content-computed-item-one-left-item-center white"
              >
                <p>{{ $t("index.v1.text17") }}</p>
                <p>
                  {{ $t("index.v1.text18") }}
                </p>
                <p>
                  {{ $t("index.v1.text19") }}
                </p>
              </div>
            </div>
            <div class="ggg"></div>
            <div
              class="three-content-computed-item-two-item w31"
              style="background-color: #f2f2f2"
            >
              <div class="three-content-computed-item-one-left-item-header">
                <div>
                  <img src="@/assets/newQJ/index/image/05@2x.png" alt="" />
                  <span>{{ $t("index.v1.text20") }}</span>
                </div>
              </div>
              <div class="three-content-computed-item-one-left-item-center">
                <p>
                  {{ $t("index.v1.text21") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="isPhone">
      <div class="new-phone-content-computed">
        <div class="content-computed-phone-title">
          {{ $t("index.v1.text6") }}
        </div>
        <div class="new-content-computed-phone-list">
          <div
            class="new-content-computed-phone-list-item"
            style="background-color: #f6f6f8"
          >
            <div class="new-content-computed-item-left-item">
              <div class="new-content-computed-item-left-header">
                <img
                  class="new-img"
                  src="@/assets/newQJ/index/image/01@2x.png"
                  alt=""
                />
                <span class="new-text"> {{ $t("index.v1.text7") }}</span>
              </div>
              <div class="new-content-computed-item-left-content">
                <p>
                  {{ $t("index.v1.text8") }}
                </p>
              </div>
            </div>
          </div>
          <div class="new-content-computed-phone-list-item">
            <img
              class="new-big-img"
              src="@/assets/newQJ/index/image/music@2x.png"
              img-type="pc"
              lazy-img="@/assets/newQJ/index/image/music@2x.png"
              alt=""
            />
          </div>
          <div
            class="new-content-computed-phone-list-item"
            style="background-color: #eae7db"
          >
            <div class="new-content-computed-item-left-item">
              <div class="new-content-computed-item-left-header">
                <img
                  class="new-img"
                  src="@/assets/newQJ/index/image/02@2x.png"
                  alt=""
                />
                <span class="new-text"> {{ $t("index.v1.text9") }}</span>
              </div>
              <div class="new-content-computed-item-left-content">
                <p>
                  {{ $t("index.v1.text10") }}
                </p>
                <p>
                  {{ $t("index.v1.text11") }}
                </p>
                <p>
                  {{ $t("index.v1.text12") }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="new-content-computed-phone-list-item"
            style="background-color: #938584"
          >
            <div class="new-content-computed-item-left-item white">
              <div class="new-content-computed-item-left-header">
                <img
                  class="new-img"
                  src="@/assets/newQJ/index/image/03@2x.png"
                  alt=""
                />
                <span class="new-text"> {{ $t("index.v1.text13") }}</span>
              </div>
              <div class="new-content-computed-item-left-content">
                <p>{{ $t("index.v1.text14") }}</p>
                <p>{{ $t("index.v1.text15") }}</p>
              </div>
            </div>
          </div>
          <div class="new-content-computed-phone-list-item">
            <img
              class="new-big-img"
              src="@/assets/newQJ/index/image/new-q@2x.png"
              img-type="pc"
              lazy-img="@/assets/newQJ/index/image/new-q@2x.png"
              alt=""
            />
          </div>
          <div
            class="new-content-computed-phone-list-item"
            style="background-color: #a4a4a1"
          >
            <div class="new-content-computed-item-left-item white">
              <div class="new-content-computed-item-left-header">
                <img
                  class="new-img"
                  src="@/assets/newQJ/index/image/04@2x.png"
                  alt=""
                />
                <span class="new-text">{{ $t("index.v1.text16") }}</span>
              </div>
              <div class="new-content-computed-item-left-content">
                <p>
                  {{ $t("index.v1.text17") }}
                </p>
                <p>
                  {{ $t("index.v1.text18") }}
                </p>
                <p>
                  {{ $t("index.v1.text19") }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="new-content-computed-phone-list-item"
            style="background-color: #f2f2f2"
          >
            <div class="new-content-computed-item-left-item">
              <div class="new-content-computed-item-left-header">
                <img
                  class="new-img"
                  src="@/assets/newQJ/index/image/05@2x.png"
                  alt=""
                />
                <span class="new-text">{{ $t("index.v1.text20") }}</span>
              </div>
              <div class="new-content-computed-item-left-content">
                <p>{{ $t("index.v1.text21") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 这是一个最好的时代 也是一个最坏的时代 -->
<script>
export default {
  name: "company",
};
</script>

<style scoped>
.isPhone {
  display: none;
}
.isPc1 {
  display: block;
}
.content-computed {
  width: 1550px;
  margin: 0 auto 0;
  color: #333333;
  padding-top: 80px;
}
.three-content-computed {
  width: 1550px;
  margin: 0 auto;
}

.three-content-computed-item-one {
  display: flex;
}
.three-content-computed-item-one-left {
  flex: 1;
}
.three-content-computed-item-one-left-title {
  font-size: 42px;
  /* font-family: MILanPro; */
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 39px;
}
.three-content-computed-item-one-left-item {
  min-height: 346px;
  background-color: #f6f6f8;
}
.three-content-computed-item-one-right {
  width: 939px;
}
.three-content-computed-item-one-right > img {
  width: 100%;
  min-height: 100%;
}
.three-content-computed-item-one-left-item-header {
  text-align: center;
  padding-top: 30px;
}
.three-content-computed-item-one-left-item-header > div {
  position: relative;
  display: inline-block;
}
.three-content-computed-item-one-left-item-header > div img {
  width: 105px;
}
.three-content-computed-item-one-left-item-header > div span {
  font-size: 30px;
  font-weight: normal;
  color: #333333;
  position: absolute;
  left: 54px;
  top: 46px;
  width: 200%;
  text-align: left;
}
.three-content-computed-item-one-left-item-center {
  margin: 30px 50px;
}
.three-content-computed-item-one-left-item-center > p {
  font-size: 16px;
  /* font-family: MILanPro; */
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.ggg {
  width: 18px;
}
.three-content-computed-item-two {
  display: flex;
  margin-top: 18px;
}
.three-content-computed-item-two-item {
  flex: 1;
}
.three-content-computed-item-two-item.w25 {
  flex: 25;
}
.three-content-computed-item-two-item.w44 {
  flex: 44;
}
.three-content-computed-item-two-item.w31 {
  flex: 31;
}
.three-content-computed-item-two-item > img {
  width: 100%;
  min-height: 100%;
}
.three-content-computed-item-one-left-item-header > div span.white {
  color: white;
}
.three-content-computed-item-one-left-item-center.white > p {
  color: #ffffff;
}
.new-content-computed-item-left-header {
  margin-bottom: 40px;
  position: relative;
}
/* 小于1550 - 1280 像素开始 */
@media screen and (max-width: 1550px) {
  .content-computed {
    width: 1165px;
    padding-top: 62px;
  }
  .three-content-computed {
    width: 1165px;
  }
  .three-content-computed-item-one-right {
    width: 758px;
  }

  .three-content-computed-item-one-left-item-header > div span {
    font-size: 24px;
    left: 43px;
    top: 37px;
  }
  .three-content-computed-item-one-left-item-center {
    margin: 28px 40px;
  }
  .three-content-computed-item-one-left-item-header > div img {
    width: 85px;
  }
  .three-content-computed-item-one-left-item-header {
    padding-top: 28px;
  }
  .three-content-computed-item-one-left-item {
    min-height: 265px;
  }
  .content-computed-first-left-list-item {
    height: 330px;
  }
  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    height: 73px;
    font-size: 38px;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 80px;
    height: 67px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 13px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 10px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 17px;
  }
  .content-lu {
    padding-top: 60px;
  }

  .content-lu-btn > ul {
    font-size: 14px;
  }
  .content-lu-btn > ul > li > img {
    width: 41px;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content-lu-btn > ul > li {
    height: 40px;
    line-height: 40px;
  }
  .content-lu-main-list {
    margin-top: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 3150px;
  }
  .content-lu-main-box-aaa {
    width: 1165px;
  }

  .content-lu-main-list-item {
    width: 300px;
    margin-right: 132px;
  }
  .content-lu-main-list-item-header {
    font-size: 19px;
    border-bottom: 1px solid #999999;
    padding-bottom: 4px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) {
    font-size: 14px;
  }
  .content-location-list-main-list {
    font-size: 14px;
  }
  .content-product {
    padding-top: 62px;
  }

  .content-product-list > .content-product-list-item {
    width: 275px;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 26px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 20px 27px 0;
    line-height: 33px;
  }

  .new-content-computed-item {
    width: 1165px;
  }
  .new-content-computed-item-left-content > p {
    font-size: 20px;
  }
  .new-content-computed-item .new-text {
    font-size: 18px;
    left: 28px;
  }
  .new-content-computed-item .new-img {
    width: 48px;
  }
  .new-content-computed-item-left-item {
    padding: 34px 28px;
  }
  .new-big-img.hh {
  }
  .content-product-list {
    width: 1165px;
  }
  .content-location {
    width: 1165px;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1440px) {
  .content-product-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-lu-title {
    font-size: 38px;
    /* font-family: MILanPro; */
  }
  .three-content-computed-item-one-left-title {
    font-size: 40px;
  }
  .content-location-title {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 24px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
  }
}
/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .content-computed {
    width: 960px;
  }
  .three-content-computed {
    width: 960px;
  }
  .three-content-computed-item-one-right {
    width: 626px;
  }
  .three-content-computed-item-one-left-title {
    font-size: 26px;
  }
  .three-content-computed-item-one-left-item-header {
    padding-top: 24px;
  }
  .three-content-computed-item-one-left-item-header > div span {
    font-size: 20px;
    left: 37px;
    top: 31px;
  }
  .content-location-list-main-list {
    font-size: 14px;
  }
  .three-content-computed-item-one-left-item-center {
    margin: 24px 40px;
  }
  .three-content-computed-item-one-left-item-header > div img {
    width: 70px;
  }
  .three-content-computed-item-one-left-item {
    min-height: 265px;
  }

  .content-computed
    > .content-computed-first
    > .content-computed-first-left
    > .content-computed-first-left-title {
    font-size: 26px;
    height: 53px;
  }
  .content-computed-first-left-list-item {
    height: 258px;
    position: relative;
  }
  .content-computed-first-left-list-item > div > div > img {
    width: 54px;
    height: 45px;
  }
  .content-computed-first-left-list-item > div > div > span {
    font-size: 14px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) {
    font-size: 12px;
    width: 156px;
    margin-top: 20px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(1) {
    margin-top: 30px;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu {
    padding-top: 40px;
  }
  .content-lu-title {
    font-size: 26px;
    margin-bottom: 38px;
  }

  .content-lu-main-box-aaa {
    width: 960px;
  }

  .content-lu-main-list-item {
    width: 280px;
    margin-right: 60px;
  }
  .content-lu-main-list {
    margin-top: 0px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p {
    margin-bottom: 12px;
  }
  .content-lu-main-list {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .content-lu-main-list-item > div:nth-of-type(2) > p:last-child {
    margin-bottom: 0;
  }
  .three-content-computed-item-one-left-item-center > p {
    font-size: 14px;
    line-height: 28px;
  }
  .content-product-title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .content-product-list > .content-product-list-item {
    width: 215px;
    margin-right: 0;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 20px;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 12px 24px 0;
    line-height: 26px;
  }
  .content-location {
    width: 960px;
    padding-top: 60px;
  }
  .content-computed-first-left-list-item > div:nth-of-type(2) > div > div {
    margin-bottom: 2px;
  }
  .content-location-title {
    font-size: 26px;
    margin-bottom: 22px;
  }
  .content-location-list-main-header {
    font-size: 14px;
  }
  .content-location-list-main-list > li {
    border-top-width: 1px;
  }
  .loId {
    font-size: 14px;
  }

  .new-content-computed-item {
    width: 960px;
  }
  .new-content-computed-item-left-item {
    padding: 21px 18px;
  }
  .new-content-computed-item-left-content > p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-product-list {
    width: 960px;
  }
  .content-location > div:nth-of-type(2) {
    width: 90%;
  }
}
/* 小于 1280 - 980 像素 结束 */

/*  750 - 980 像素 结束 */
@media screen and (max-width: 980px) {
  .isPc1 {
    display: none !important;
  }
  .isPhone {
    display: block;
  }
  .max.isPC {
    display: block !important;
  }
  .min.isPhone {
    display: none;
  }
  .content-computed {
    width: 700px;
  }

  .new-content-computed-item-left-content > p {
    font-size: 14px;
  }
  .content-location {
    width: 700px;
  }
  .content-location > div:nth-of-type(2) {
    width: 700px;
    margin: 0 auto;
  }
  .content-computed-phone {
    width: 700px;
  }
  .content-computed-phone-title {
    font-size: 26px;
    margin-bottom: 32px;
    text-align: center;
  }
  .content-computed-phone-img {
    width: 100%;
  }
  .content-computed-phone-list > div {
    width: 50%;
    display: inline-block;
    height: 535px;
    float: left;
  }
  .content-computed-phone-list-item-header {
    text-align: center;
    padding-top: 47px;
    margin-bottom: 43px;
  }
  .content-computed-phone-list-item-header > div {
    display: inline-block;
    position: relative;
  }
  .content-computed-phone-list-item-header > div > img {
    width: 128px;
    height: 106px;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26px;
    position: absolute;
    right: 0;
    bottom: -4px;
  }
  .content-computed-phone-list-item-content {
    margin: 0 50px;
    font-size: 22px;
    color: #333333;
  }
  .content-computed-phone-list-item-content.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item.white {
    color: #ffffff;
  }
  .content-computed-phone-list-item-content > p {
    margin-bottom: 10px;
  }
  .content-computed-phone-list-item > img {
    width: 100%;
    height: 535px;
  }

  .new-content-computed-phone-list-item {
    width: 700px;
  }
  .new-big-img {
    width: 100%;
  }

  .new-img {
    width: 50px;
  }
  .new-text {
    font-size: 20px;
    position: absolute;
    bottom: -19px;
    left: 31px;
  }
  .new-content-computed-item-left-item {
    padding: 30px 30px;
  }
  .new-content-computed-item-left-item.white {
    color: #ffffff;
  }
  .content-product-list {
    width: 700px;
  }
  .content-lu-main-box-aaa {
    width: 700px;
  }
  .content-lu-main-list-item {
    width: 300px;
    margin-right: 100px;
  }
  .content-product-list > .content-product-list-item {
    width: 165px;
  }
}

/* 750- 540 适配 za*/
@media screen and (max-width: 750px) {
  .isPhone.min {
    display: block !important;
  }
  .isPC.max {
    display: none !important;
  }
  .content-computed {
    width: 100%;
    padding-top: 30px;
  }
  .new-content-computed-phone-list-item {
    width: 100%;
  }
  .content-computed-phone {
    width: 100%;
  }
  .content-computed-phone-title {
    margin-bottom: 30px;
  }

  .content-computed-phone-list-item-header > div > img {
    width: 128rem;
    height: 106rem;
  }
  .content-computed-phone-list-item-header {
    padding-top: 47rem;
    margin-bottom: 43rem;
  }
  .content-computed-phone-list-item-header > div > span {
    font-size: 26rem;
  }
  .content-computed-phone-list > div,
  .content-computed-phone-list-item > img {
    height: 532rem;
  }
  .content-computed-phone-list-item-content {
    margin: 0 30rem;
    font-size: 22rem;
  }
  .content-lu {
    padding-top: 30px;
  }
  .content-lu-title {
    margin-bottom: 50px;
  }
  .content-lu-btn > ul {
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 25px;
  }
  .content-lu-main-list {
    width: 2400px;
    padding-bottom: 40px;
  }
  .content-lu-main-box-aaa {
    width: 90%;
    overflow: auto;
  }
  .content-lu-main-list-item {
    width: 100%;
    display: table-cell;
    margin-right: 1%;
  }
  .content-lu-main-list-item:nth-of-type(1) {
    margin-left: 0;
  }
  .content-lu-main-list-item-header {
    font-size: 20px;
  }
  .content-lu-btn > ul > li > img {
    top: -44px;
  }
  .content-product {
    padding-top: 30px;
    position: relative;
    top: -20px;
    z-index: 2;
    background-color: #fff;
  }
  .content-product-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  .content-product-list > .content-product-list-item {
    width: 45%;
    margin: 0 0 3.3% 0;
  }
  .content-product-list > .content-product-list-item > div {
    padding-top: 17.5px;
    min-height: 110px;
  }
  .content-product-list > .content-product-list-item > div > div {
    font-size: 33rem;
  }
  .content-product-list > .content-product-list-item > div > p {
    font-size: 24rem;
    margin: 3px 10px 0;
  }
  .content-location {
    width: 100%;
    background-color: #fbfbfb;
    padding-top: 15px;
  }
  .content-location-title {
    padding-top: 25px;
    font-size: 25px;
    margin-bottom: 35px;
  }
  .isPhone-list {
    padding: 10px;
    font-size: 24rem;
    margin-bottom: 8px;
  }
  .isPhone-list-item {
    border-top: 0.5px solid #eeeeee;
    margin-left: 14px;
  }
  .isPhone-list-item-header {
    display: flex;
    justify-content: space-between;
    padding-top: 19px;
    padding-bottom: 15px;
    font-size: 26rem;
  }
  .isPhone-list-item-header > img {
    width: 15px;
    height: 13px;
  }
  .isPhone-list-item-content {
    font-size: 26rem;
    margin-bottom: 16px;
  }
  .loading {
    width: 350px;
    padding: 0 28px 37px;
  }
  .loading-header {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 16px;
    margin-top: 35px;
  }
  .loading-content {
    padding-top: 12px;
    margin-bottom: 0;
  }
  .loading-content > p:nth-of-type(1) {
    margin-top: 0;
  }
  .loading-content > p {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-content > div {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-bottom {
    margin-top: 40px;
  }
  .loading-bottom > div {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
  .new-text {
    font-size: 16px;
    top: 23px;
  }
  /*::-webkit-scrollbar {*/
  /*    width:12px;*/
  /*}*/
  /*!* 滚动槽 *!*/
  /*::-webkit-scrollbar-track {*/
  /*    -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,0.3);*/
  /*    border-radius:0px;*/
  /*}*/
  /*!* 滚动条滑块 *!*/
  /*::-webkit-scrollbar-thumb {*/
  /*    border-radius:10px;*/
  /*    background:rgba(0,0,0,0.1);*/
  /*}*/
}
</style>
