<template>
  <div class="index">
    <video
      class="video"
      id="audios"
      poster="@/assets/image/video.jpg"
      autoplay="autoplay"
      x5-video-player-type="h5-page"
      webkit-playsinline="true"
      playsinline="true"
      disablePictureInPicture
      muted
      controls
      controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
    >
      <source src="https://image.iriding.cc/video/video.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div>
      <company></company>
      <lu></lu>
      <product></product>
      <location></location>
    </div>
    <load @changeCookieStatus="changeCookieStatus" :isShow="isShow"></load>
    <to-top />
  </div>
</template>

<script>
import company from "./components/company";
import lu from "./components/lu";
import product from "./components/product";
import location from "./components/location";
import load from "../../../../components/load/load";
import ToTop from "../../component/totop/toTop.vue";
export default {
  name: "index",
  data() {
    return {
      isShow: false,
    };
  },
  components: {
    company,
    lu,
    product,
    location,
    load,
    ToTop,
  },
  methods: {
    changeCookieStatus() {
      this.isShow = false;
    },
  },
  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function () {
      document.getElementById("audios").play();
    });
    if (localStorage.getItem("cookieStatus") !== "1") {
      this.isShow = true;
    }
  },
};
</script>

<style scoped lang="stylus">
.index {
  margin-top: 72px;

  .video {
    width: 100%;
  }
}

@media screen and (max-width: 980px) {
  .index {
    margin-top: 50px;
  }
}
</style>
