<template>
  <div :class="['load', isShow ? '' : 'not']">
    <div :class="['loading', isShow ? '' : 'not']" id="publicLoading">
      <div class="loading-header">{{ $t("index.v1.text88") }}</div>
      <div class="loading-content">
        <p>{{ $t("index.v1.text89") }}</p>
        <div>
          <span>{{ $t("index.v1.text100") }}</span>
          <span
            style="border-bottom: 1px solid #333333; cursor: pointer"
            id="goPrivar"
          >
            <router-link target="_blank" :to="`/${value}/privacy`">{{
              $t("index.v1.text101")
            }}</router-link> </span
          >。
        </div>
      </div>
      <div class="loading-bottom">
        <div @click="activeLanguage('1')">{{ $t("index.v1.text94") }}</div>
        <div @click="activeLanguage('0')" style="margin-bottom: 0">
          {{ $t("index.v1.text93") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "load",
  data() {
    return {
      value: "",
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.value = localStorage.getItem("lans")
      ? localStorage.getItem("lans")
      : "zh-cn";
  },
  methods: {
    activeLanguage(status) {
      this.$emit("changeCookieStatus", false);
      localStorage.setItem("cookieStatus", status);
    },
  },
};
</script>

<style scoped>
.load {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.load.not {
  top: -1000px;
  opacity: 0;
  z-index: -99;
}
.loading {
  position: fixed;
  width: 782px;
  padding: 0 68px 64px;
  z-index: 999;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  top: 50%;
  transition: all 1s;
  opacity: 1;
  min-width: 320px;
  box-sizing: border-box;
}
.loading.not {
  top: -1000px;
  opacity: 0;
  z-index: -99;
}
.loading-header {
  font-size: 30px;
  color: #2a2e2e;
  letter-spacing: 0;
  width: 100%;
  text-align: left;
  margin-top: 64px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9ecf0;
}
.loading-content {
  font-size: 14px;
  color: #5f6464;
  letter-spacing: 0;
  line-height: 25px;
  padding-top: 24px;
  margin-bottom: 82px;
}
.loading-content > p {
  margin-bottom: 30px;
}
.loading-content > div {
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 82px;
}
.loading-content > div > a {
  color: #4d4d4d;
  text-decoration: revert;
}
.loading-bottom > div {
  float: right;
  width: 200px;
  height: 40px;
  margin-right: 16px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #2a2e2e;
  background: #f2f3f5;
  cursor: pointer;
  transition: all 0.3s;
}
.loading-bottom > div:hover {
  background-color: #999999;
  color: #ffffff;
}
.router-link-active {
  text-decoration: none;
  color: #5f6464;
}
a {
  text-decoration: none;
  color: #5f6464;
}
@media screen and (max-width: 750px) {
  .loading {
    width: 80%;
    padding: 0 28px 37px;
  }
  .loading-header {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 16px;
    margin-top: 35px;
  }
  .loading-content {
    padding-top: 12px;
    margin-bottom: 0;
  }
  .loading-content > p:nth-of-type(1) {
    margin-top: 0;
  }
  .loading-content > p {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-content > div {
    margin-top: 25px;
    font-size: 14px;
    margin-bottom: 26px;
  }
  .loading-bottom {
    margin-top: 40px;
  }
  .loading-bottom > div {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
  }
}
</style>
